/* .rbc-row.rbc-time-header-cell { 
    width: 220%;
  } */

.calendar.week .rbc-day-slot.rbc-time-column .rbc-timeslot-group {
  width: 250px;
}

.calendar.week .rbc-calendar {
  position: relative;
  /* padding-left: 50px; */
}

.calendar.week .rbc-time-gutter.rbc-time-column {
  /* position: absolute;
      left: 0;
      top: 100px;
      z-index: 1;
      background: white; */
  position: sticky;
  left: 0;
  top: 0;
  z-index: 4;
  background: rgb(244, 246, 249) !important;
}

.calendar.week .rbc-time-content {
  /* position: initial; */
  overflow-y: initial;
  border-top: initial !important;
  z-index: 2;
}

/* .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
    margin-left: 50px;
  } */

.calendar.week .rbc-time-view {
  overflow-y: auto;
  position: absolute;
  height: 820px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.calendar.week .rbc-time-header {
  /* border-bottom: 2px solid #ddd; */
  border-bottom: initial;
  z-index: 3;
  position: sticky;
  top: 0;
  bottom: 0;
  background: var(--inventory-border-bg);
}

.calendar.week .rbc-current-time-indicator {
  width: 700%;
}

.rbc-time-header-content {
  min-width: initial;
}

.rbc-header {
  width: 252px;
}

.rbc-toolbar .rbc-btn-group:last-child {
  display: none;
}

.rbc-toolbar {
  position: relative;
  height: 50px;
  margin-bottom: initial;
}

.rbc-toolbar .rbc-btn-group {
  margin-top: -30px;
}

/* 
.rbc-toolbar .rbc-btn-group:first-child button:nth-child(1) {
  display: none;
} */

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(1) {
  position: absolute;
  left: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  gap: 10px;
  box-shadow: 0px 0px 24px 0px #0000001a;
  border: 1px solid var(--revenue-dashboard-border-color);
  background: var(--inventory-border-bg);
}

.rbc-toolbar .rbc-btn-group button span {
  transform: translateX(-50%);
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(2) {
  position: absolute;
  right: 30px;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  gap: 10px;
  box-shadow: 0px 0px 24px 0px #0000001a;
  border: 1px solid var(--revenue-dashboard-border-color);
  background: var(--inventory-border-bg);
}

.rbc-time-content>*+*>* {
  border-left: initial;
}

.rbc-allday-cell .rbc-row-content {
  display: none;
}

.rbc-time-content>.rbc-day-slot {
  background-color: var(--inventory-border-bg);
  border: 1px solid var(--revenue-dashboard-border-color);
}

.rbc-toolbar {
  background: var(--inventory-border-bg);
  height: 90px;
}

.rbc-timeslot-group {
  min-height: 91px;
  position: relative;
  z-index: revert-layer;
}

.rbc-day-slot.rbc-time-column .rbc-timeslot-group div.new {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.rbc-time-view {
  border: initial;
}

.rbc-time-content {
  border-top: initial !important;
}

span.rbc-label {
  font-size: 10px;
  color: var(--main-color-main-optional-primary-3-color);
}

.rbc-time-slot {
  position: relative;
  width: 60px;
}

.rbc-time-slot span {
  position: absolute;
  left: 0;
}

.rbc-time-gutter.rbc-time-column .rbc-timeslot-group {
  border: initial;
  z-index: 2;
}

.rbc-current-time-indicator {
  height: 1px;
  background-color: var(--collapse-background-color);
  border-radius: 8px;
}

.rbc-current-time-indicator {
  display: none;
}

.custom-time-indicator {
  height: 2px;
  background: var(--collapse-background-color);
  position: relative;
  z-index: 10;
  left: 8px;
  width: calc(100% - 70px);
}

.custom-time-indicator span {
  position: absolute;
  left: -2px;
  top: 10px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--collapse-background-color);
  transform: translateY(-100%);
}

.custom-time-indicator.now {
  left: 0;
  width: 100%;
}

.custom-time-indicator.now span {
  display: none;
}

.rbc-day-slot .rbc-events-container {
  margin-right: initial;
}

.rbc-time-gutter.rbc-time-column .rbc-timeslot-group div {
  background: rgb(244, 246, 249) !important;
}

.rbc-timeslot-group:hover .add-new {
  display: block;
}

.event-action {
  transform: rotate(90deg) translateY(-50%);
  display: inline-block;
  color: inherit;
}

.calendar.week .rbc-time-view .rbc-row {
  min-height: initial;
}

.calendar.week .weekday {
  height: 90px;
  padding: 16px 8px 8px 8px;
  display: flex;
  align-items: normal;
  justify-content: space-between;
}

.calendar.week .rbc-header {
  text-align: left;
  border: initial;
}

.calendar.week .rbc-header button {
  width: 100%;
  position: relative;
}

.calendar.week .rbc-header button::after {
  content: '';
  position: absolute;
  top: calc(50% - 20px);
  right: 0;
  width: 1px;
  height: 20px;
  background: #ddd;
}

.calendar.week .rbc-time-header-content {
  border-left: initial;
}

.calendar.week .rbc-header.rbc-today {
  background: initial;
}

.calendar.week .rbc-toolbar {
  left: 0;
  right: 0;
  z-index: 4;
  position: initial;
  height: 0;
}

.calendar.week .rbc-toolbar .rbc-btn-group:first-child button {
  top: 29px;
}

.calendar.week .toolbar-current-time {
  display: none;
}

.calendar.week .rbc-toolbar .rbc-btn-group {
  z-index: 2;
}

.calendar.week .weekday span.off {
  width: 57px;
  height: 22px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  gap: 10px;
  background: var(--neutral-black-2-color);
  display: block;
}

.calendar.week .custom-time-indicator.now .timeline {
  position: absolute;
  left: -834px;
  right: -834px;
  height: 2px;
  background: var(--collapse-background-color);
}

.calendar.week .weekday.selected {
  border-bottom: 3px solid var(--collapse-background-color);
}

.calendar.week .weekday.selected .week-day-name,
.calendar.week .weekday.selected .week-day {
  color: var(--collapse-background-color);
}

.calendar.day .rbc-toolbar.seleted .toolbar-current-time .day-name,
.calendar.day .rbc-toolbar.seleted .toolbar-current-time .day {
  color: var(--collapse-background-color);
}

.calendar.day .rbc-toolbar.seleted {
  border-bottom: 3px solid var(--collapse-background-color);
}

.calendar.week .custom-timeline {
  position: absolute;
  left: 0;
  right: 0;
  top: 63px;
  height: 2px;
  background: initial;
}

.calendar.week .custom-timeline .line {
  position: absolute;
  left: 60px;
  right: 0;
  height: 2px;
  background: var(--collapse-background-color);
  z-index: 2;
}

.calendar.week .custom-timeline span {
  position: absolute;
  left: 8px;
  top: 0px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--collapse-background-color);
  transform: translateY(-100%);
  z-index: 3;
}