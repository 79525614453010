.create-appointment.modal .ant-col-12 {
  padding-right: 15px;
}

.create-appointment.modal .ant-modal .ant-modal-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
}

.create-appointment.modal .ant-form-item .ant-form-item-label>label {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
  opacity: 70%;
}

.create-appointment.modal .ant-input,
.create-appointment.modal .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.create-appointment.modal .ant-picker {
  height: 36px;
  padding: 8px 8px 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--inventory-border-color);
  gap: 8px;
}

.create-appointment.modal .ant-form-item .ant-form-item-label {
  width: 100%;
  text-align: left;
}

.create-appointment.modal .ant-picker {
  width: 100%;
}

.create-appointment.modal .ant-input.note {
  height: 100px;
}

.create-appointment.modal .ant-form-item .ant-form-item-label>label.ant-form-item-required::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: var(--inventory-border-color2);
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  visibility: initial;
}

.create-appointment.modal .ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.create-appointment.modal .appointment .ant-btn-default {
  width: 120px;
  height: 36px;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  border: 1px;
  gap: 8px;
  border: 1px solid var(--inventory-border-color);
}

.create-appointment.modal .ant-modal .ant-modal-footer .appointment .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
  width: 104px;
  height: 36px;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  gap: 8px;
  background: var(--other-blue-color);
}

.create-appointment.modal .ant-modal-content {
  width: 718px;
}

.create-appointment.modal .hidden {
  display: none;
}

.create-appointment.modal .duration {
  margin-left: 15px;
}

.create-appointment.modal .ant-form-item .ant-form-item-explain-error {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 5px;
}