.create-appointment.infohover {
  min-width: 487px !important;
}

.create-appointment.infohover.hidden {
  display: none;
}

.create-appointment.infohover .box .author .author-content .info :where(.css-dev-only-do-not-override-xu9wm8).ant-avatar-image {
  width: 25px;
  height: 25px;
}

.create-appointment.infohover .box-content-info-item:nth-child(2) {
  width: 50%;
}

.create-appointment.infohover.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.create-appointment.infohover.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active:hover {
  background: white;
}

.create-appointment.infohover li {
  padding: 0px;
  margin: 0;
}