.Collapse-treatment {
  border: initial;
  background: initial;
  box-shadow: initial;
}

.Collapse-treatment .ant-collapse-item {
  border: initial;
  background: initial;
  box-shadow: initial;
}

.search-appointment .row-status-2 td {
  /* background: #ECFDF2; */
  background: white;
}


.search-appointment .row-status-3 td {
  background: var(--states-orange-light-color);
}


.search-appointment .row-status-4 td {
  background: var(--states-blue-light-color);
}


.search-appointment .row-status-5 td {
  background: #E8F6F9;
}


.search-appointment .row-status-6 td {
  background: var(--states-red-light-color);
}


.search-appointment .row-status-7 td {
  background: #FDE9FF;
}


.search-appointment .row-status-8 td {
  background: #F3F4F6;
}

.search-appointment tr td.ant-table-cell-row-hover {
  background: initial !important;
}

.search-appointment .selected-status .ant-select-selection-item {
  text-align: center;
}

.search-appointment .ant-select .ant-select-arrow {
  color: white;
}

.appointment-modal .ant-modal-content {
  padding: 0px;
}

.appointment-modal .ant-modal-content>div,
.appointment-resize {
  zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(1);
  -webkit-transform-origin: 0 0;
}

.search-appointment .ant-table-tbody .ant-table-cell {
  vertical-align: top;
}

.search-appointment .ant-table-tbody .ant-table-cell.status-column{
  vertical-align: middle;
}