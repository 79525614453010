.confirm-modal .ant-modal-footer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.confirm-modal .ant-modal-footer button {
  width: 120px;
  height: 36px;
}

.confirm-modal .ant-modal-footer button:first-child {
  border: 1px solid var(--inventory-border-color);
}

.confirm-modal .ant-modal-footer button span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.confirm-modal .ant-modal-footer button.ant-btn-primary {
  background: var(--other-blue-color);
}