.ant-picker-content th {
  font-family: Inter !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: var(--checkbox-border-color) !important;
}

.ant-picker-content .ant-picker-calendar .ant-picker-cell {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--main-color-main-optional-primary-3-color);
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner .ant-picker-calendar-date-value,
.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner .ant-picker-calendar-date-value,
.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner .ant-picker-calendar-date-value {
  padding-top: 6px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 1px;
  gap: 4px;
  background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, var(--states-orange-color), var(--states-orange-color));
  border: 1px solid var(--states-orange-color);
  color: var(--main-color-text);
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner,
.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: initial;
  border: initial;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  background: initial;
  border: initial;
}

.ant-picker-cell-in-view .ant-picker-calendar-date-value {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--main-color-text);
  padding-top: 6px;
  width: 30px;
  height: 30px;
}

.ant-picker-calendar .ant-picker-cell-in-view {
  background: initial;
  border: initial;
}

.ant-picker-calendar .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-calendar .ant-picker-cell:hover:not(.ant-picker-cell-today):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: initial;
  border: initial;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner .ant-picker-calendar-date-value {
  padding-top: 6px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 1px;
  gap: 4px;
  background: var(--other-blue-color);
  border: 1px solid var(--other-blue-color);
  color: white;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: initial;
  border: initial;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner::before {
  background: initial;
  border: initial;
}

.ant-picker-calendar .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-calendar .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: initial;
  border: initial;
}

.calendar-left {
  background: white;
  border-radius: 8px 0px 0px 8px;
}

.calendar-left .scrollabelDiv {
  border: initial;
  height: 400px;
  overflow: auto;
  padding: 0px 10px;
}

.calendar-left .scrollabelDiv .list-item {
  padding: 0px 5px;
  cursor: pointer;
  position: relative;
}

.calendar-left .scrollabelDiv .list-item:hover,
.calendar-left .scrollabelDiv .list-item.selected {
  background: var(--states-blue-light-color);
}

.calendar-left .scrollabelDiv .list-item img.selected {
  display: none;
}

.calendar-left .scrollabelDiv .list-item.selected img.selected {
  position: absolute;
  right: 5px;
  top: calc(50% - 9px);
  display: block;
}

.calendar-left .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--neutral-black-2-color);
}

.calendar-left .ant-select-selection-item {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--main-color-text);
}

.calendar-left .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title>a {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
}

.calendar-left .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  margin: initial;
}

.calendar-left .ant-list-item .ant-list-item-meta .ant-list-item-meta-avatar {
  margin-inline-end: 10px;
}

.calendar-left .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 70%;
}

.calendar-left .ant-list .ant-list-item .ant-list-item-meta {
  align-items: center;
}

.create-appointment .title {
  display: flex;
}

.create-appointment .title .month {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
  display: flex;
}

.create-appointment .title .year {
  opacity: 70%;
  padding: 0px 5px 0px 3px;
}

.create-appointment .title .doctor-name {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--collapse-background-color);
  padding-left: 5px;
}

.create-appointment .calendar-left .doctor-total {
  padding: 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color-text);
}

.create-appointment .title-box .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 88px;
  height: 32px;
  padding: 8px;
  border-radius: 8px;
  border: 1px;
  gap: 8px;
  border: 1px solid var(--neutral-black-2-color);
}

.create-appointment .title-box .ant-row {
  align-items: center;
}

.create-appointment .title-box {
  margin-bottom: 10px;
  margin-left: 15px;
}

.create-appointment .title-box .ant-select-single {
  margin-right: 5px;
  justify-content: end;
  display: flex;
}

.create-appointment.title-box.annotate .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background: initial !important;
}

.calendar-left .ant-input-affix-wrapper {
  border: 1px solid var(--revenue-dashboard-border-color);
  background: var(--revenue-dashboard-border-color);
}

.calendar-left .ant-input-affix-wrapper>input.ant-input {
  background: var(--revenue-dashboard-border-color);
}

.create-appointment .select-calendar-type .ant-select-selector {
  background: initial;
  border: initial !important;
}


.create-appointment .select-calendar-type .ant-select-focused .ant-select-selector,
.create-appointment .select-calendar-type .ant-select-selector:focus,
.create-appointment .select-calendar-type .ant-select-selector:active,
.create-appointment .select-calendar-type .ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.appointment-fontsize {
  font-size: 16px;
}

.app-bg-white {
  background: white;
}

.app-width-full {
  width: 100%;
}

.app-row-margin {
  margin-bottom: 15px;
  margin-left: 15px;
}

.app-color-close {
  color: var(--main-color-main-optional-primary-3-color);
}

/* .create-appointment .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  width: 100%;
} */