@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  display: initial;
}

div {
  font-family: Inter;
}

span {
  font-family: Inter;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

@keyframes slide-right {
  0% {
    transform: translateX(calc(200px));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(-200px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(calc(200px));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes opacity-up-to {
  0% {
    opacity: 0;
    transform: translateY(calc(200px));
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes text-opacity-up-to {
  0% {
    opacity: 0;
    transform: translateY(calc(5px));
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.container-left-to {
  animation: slide-left 3s alternate;
}

.container-down-to {
  animation: slide-down 3s alternate;
}

.container-up-to {
  animation: slide-up 3s alternate;
}

.container-right-to {
  animation: slide-right 3s alternate;
}

.container-opacity-up-to {
  animation: opacity-up-to 3s alternate;
}

.text-opacity-up-to {
  animation: text-opacity-up-to 1s alternate;
}

.flex-one {
  display: flex;
  flex: 1;
}

.scroll-to-top {
  position: 'fixed';
  bottom: '200px';
  right: '50px';
  background-color: white;
  border-radius: '30px';
  box-shadow: '0.5px 1px 2px gray';
}


.ant-popover-inner {
  padding: 0px !important;
}