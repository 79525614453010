.schedule-box {
  width: 95%;
  height: 62px;
  padding: 12px;
  border-radius: 8px;
  gap: 16px;
}

.schedule-box-list {
  height: 42px;
  gap: 4px;
}